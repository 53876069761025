function getEnvVariable(name) {
    if (window.config && window.config[name]) {
        return window.config[name]
    }

    return process.env[name]
}

export const BACKEND_MAPPING = JSON.parse(getEnvVariable('VUE_APP_BACKEND_MAPPING'));
let backend_url = getEnvVariable('VUE_APP_BACKEND_SERVER');
if (!backend_url) {
    backend_url = BACKEND_MAPPING[document.location.host]
}
console.log(backend_url);
export const BACKEND_URL = backend_url;
